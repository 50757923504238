import React from "react";
import Container from "@material-ui/core/Container";
import { Banner, theme } from "components";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { makeStyles, MuiThemeProvider } from "@material-ui/core/styles";
import { CompoundGrowth, ReverseMortgage, TrueCost } from "pages";

const useStyles = makeStyles({
  root: {
    paddingTop: "32px",
  },
  nav: {
    display: "flex",
    justifyContent: "space-around",
    listStyle: "none",
  },
  titleContainer: {
    position: "relative",
  },
  backButton: {
    position: "absolute",
    top: 0,
  },
});

function App() {
  const classes = useStyles();

  return (
    <Router>
      <MuiThemeProvider theme={theme}>
        <Banner />
        <Container className={classes.root} maxWidth="md">
          <Switch>
            <Route path="/true-cost-calculator">
              <TrueCost />
            </Route>
            <Route path="/reverse-mortgage-calculator">
              <ReverseMortgage />
            </Route>
            <Route path="/compound-growth-calculator">
              <CompoundGrowth />
            </Route>
            <Route>
              <TrueCost />
            </Route>
          </Switch>
        </Container>
      </MuiThemeProvider>
    </Router>
  );
}

export default App;
