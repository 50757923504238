import React from "react";
import BottomBar from "./components/BottomBar";
import MiddleBar from "./components/MiddleBar";
import TopBar from "./components/TopBar";
import styles from "./Banner.module.css";

export default function Banner() {
  return (
    <div className={styles.fullscreenContainer}>
      <TopBar />
      <MiddleBar />
      <BottomBar />
    </div>
  );
}
