import React from "react";
import styles from "./BottomBar.module.css";

export default function BottomBar() {
  return (
    <div className={styles.fullscreenContainer}>
      <div className={styles.container}>
        <a href="https://smithman.net/free-resources/">Free Resources</a>
      </div>
    </div>
  );
}
