import React, { useState } from "react";
import classNames from "classnames";
import logo from "components/Banner/logo.png";
import { Link } from "react-router-dom";
import middleBarItems from "./middleBarItems";
import styles from "./MiddleBar.module.css";

export default function Banner() {
  const [selectedTab, setSelectedTab] = useState(0);
  return (
    <div className={styles.middleContainer}>
      <a href="https://smithman.net">
        <img alt="logo" src={logo} />
      </a>
      <ul className={styles.nav}>
        {middleBarItems.map((navItem, index) => (
          <li
            className={classNames(styles.navItem, {
              [styles.selectedBottom]: selectedTab === index,
            })}
            key={navItem.id}
          >
            <Link
              className={classNames(styles.navLink, {
                [styles.selected]: selectedTab === index,
              })}
              onClick={() => setSelectedTab(index)}
              to={navItem.to}
            >
              {navItem.text}
            </Link>
          </li>
        ))}
      </ul>
      <a className={styles.button} href="https://smithman.net">
        <span className={styles.span}>Back</span>
      </a>
    </div>
  );
}
