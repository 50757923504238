import React from "react";
import styles from "./TopBar.module.css";
import topBarItems from "./topBarItems";

export default function TopBar() {
  return (
    <div className={styles.fullscreenContainer}>
      <div className={styles.container}>
        {topBarItems.map((item, i, array) => (
          <>
            <a className={styles.item} href={item.to} key={item.id}>
              {item.text}
            </a>
            {i < array.length - 1 && (
              <span className={styles.separator}>|</span>
            )}
          </>
        ))}
      </div>
    </div>
  );
}
