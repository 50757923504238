// https://www.thecalculatorsite.com/articles/finance/compound-interest-formula.php
export default function reverseMortgageCompound({
  annualRate,
  compoundPerYear = 2, // By default, semi-annually compound in Canada
  principal,
  totalYear,
}) {
  return (
    principal *
    (1 + annualRate / compoundPerYear) ** (compoundPerYear * totalYear)
  );
}
