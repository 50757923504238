import React from "react";
import PropTypes from "prop-types";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles({
  root: {
    fontSize: "24px",
  },
});

export default function H2({ children }) {
  const classes = useStyles();

  return (
    <Typography
      align="center"
      className={classes.root}
      component="h2"
      gutterBottom
      variant="h2"
    >
      {children}
    </Typography>
  );
}

H2.propTypes = {
  children: PropTypes.string.isRequired,
};
