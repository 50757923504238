function convertToMonthlyInterest(mortgageRate) {
  return +((1 + mortgageRate / 2) ** (2 / 12) - 1).toFixed(9);
}

function getMonthlyPaymentAmount({ balance, totalYears, rate }) {
  const periodicRate = convertToMonthlyInterest(rate);
  const numPayments = totalYears * 12;

  // https://en.wikipedia.org/wiki/Amortization_calculator
  return (
    balance *
    (periodicRate + periodicRate / ((1 + periodicRate) ** numPayments - 1))
  );
}

export default function getBeforeTaxIncomeNeeded({
  balance,
  mtr,
  rate,
  totalYears,
}) {
  return (
    (getMonthlyPaymentAmount({ balance, totalYears, rate }) * totalYears * 12) /
    (1 - mtr)
  );
}
