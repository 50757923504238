import React, { useState } from "react";
import classnames from "classnames";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Slider from "@material-ui/core/Slider";
import Typography from "@material-ui/core/Typography";
import { H2, NumberInput } from "components";
import { reverseMortgageCompound, reverseMortgageTable } from "maths";
import useStyles from "./styles";
import { ageMarks, paybackMarks } from "./marks";
import { formatResult } from "../utils";
import { introText } from "./strings";

const MAX_BALANCE = 99999999;
const MAX_RATE = 10;

export default function ReverseMortgage() {
  const classes = useStyles();

  const [age, setAge] = useState(55);
  const [paybackDuration, setPaybackDuration] = useState(5);
  const [houseValue, setHouseValue] = useState(0);
  const [mortgageRate, setMortgageRate] = useState(5.59);

  const portionAvailable = reverseMortgageTable[age];
  const rmAvailable = portionAvailable * houseValue;

  return (
    <div className={classes.root}>
      <H2>Reverse Mortgage Calculator</H2>
      <p className={classes.instructions}>{introText}</p>
      <Grid container spacing={3}>
        <Grid item xs={7}>
          <Paper className={classes.paper}>
            <Typography className={classes.sliderLabel} gutterBottom>
              Indicate your age at reverse mortgage sign-up
            </Typography>
            <Slider
              className={classes.topSlider}
              defaultValue={55}
              marks={ageMarks}
              max={80}
              min={55}
              onChange={(event, value) => setAge(value)}
              value={age}
              valueLabelDisplay="on"
            />
            <Typography className={classes.sliderLabel} gutterBottom>
              Indicate estimated number of years holding the reverse mortgage
            </Typography>
            <Slider
              className={classes.bottomSlider}
              defaultValue={10}
              marks={paybackMarks}
              max={30}
              min={5}
              onChange={(event, value) => setPaybackDuration(value)}
              value={paybackDuration}
              valueLabelDisplay="on"
            />
            <NumberInput
              label="House Value"
              max={MAX_BALANCE}
              onChange={setHouseValue}
              value={houseValue}
            />
            <Typography gutterBottom>
              Based on your age, you may borrow approximately{" "}
              {(portionAvailable * 100).toFixed(1)}% of the house value, which
              equals roughly ${formatResult(rmAvailable)}.
            </Typography>
            <NumberInput
              label="Interest rate"
              max={MAX_RATE}
              onChange={setMortgageRate}
              prefix=""
              suffix=" %"
              value={mortgageRate}
            />
          </Paper>
        </Grid>
        <Grid item xs={5}>
          <Paper className={classnames(classes.paper, classes.result)}>
            <p>
              After {paybackDuration} years, based on borrowing $
              {formatResult(rmAvailable)}, you will owe an estimated total of...
            </p>
            <p className={classes.resultNumber}>
              $
              {formatResult(
                reverseMortgageCompound({
                  annualRate: mortgageRate / 100,
                  principal: rmAvailable,
                  totalYear: paybackDuration,
                }),
              )}
            </p>
            <p>
              This includes the original amount of borrowing and accrued
              interest.
            </p>
          </Paper>
        </Grid>
      </Grid>
      <p className={classes.instructions}>
        <em>The Smith Manoeuvre</em> will enable you to build up a portfolio of
        investments which can generate cash flow in your retirement which may
        enable you to avoid a reverse mortgage.
      </p>
      <Paper className={classes.root}></Paper>
    </div>
  );
}
