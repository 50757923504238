const reverseMortgageTable = {};

let portionAvail = 0.2; // 20% at the age of 55
for (let age = 55; age <= 80; age++) {
  reverseMortgageTable[age] = portionAvail;
  portionAvail += 0.014;
}

/**
 * Results:
 * {
 *    55: 20%,
 *    56: 21.4%,
 *    ...
 *    80: 55%
 * }
 */

export default reverseMortgageTable;
