import React, { useState } from "react";
import classnames from "classnames";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import { getCompoundedPortfolio } from "maths";
import { H2, NumberInput } from "components";
import { makeStyles } from "@material-ui/core/styles";
import { formatResult } from "./utils";

const MAX_DURATION = 60;

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2, 2),
  },
  instructions: {
    fontSize: "14px",
  },
  result: {
    fontSize: "20px",
    textAlign: "center",
    padding: "3px 16px",
  },
  resultNumber: {
    fontSize: "50px",
    fontWeight: "bold",
    margin: "37px 10px",
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
  },
}));

export default function TrueCost() {
  const classes = useStyles();

  const [initialInvestment, setInitialInvestment] = useState("");
  const [growthRate, setGrowthRate] = useState("");
  const [regularContribution, setRegularContribution] = useState("");
  const [totalYears, setTotalYears] = useState("");

  function getCompoundedResult() {
    return [initialInvestment, growthRate, totalYears].every(input => input)
      ? formatResult(
          getCompoundedPortfolio({
            growthRate: growthRate / 100,
            initialInvestment: +initialInvestment,
            regularContribution: +regularContribution,
            totalYears: +totalYears,
          }),
        )
      : 0;
  }

  return (
    <div className={classes.root}>
      <H2>Compound Growth Calculator</H2>
      <p className={classes.instructions}>
        When you are enjoying compound growth, you are enjoying a return on not
        only your original investment amount but also a return on the return of
        that investment. This is why compound growth is so powerful. The growth
        of your portfolio speeds up over time - it accelerates exponentially, so
        the more you invest and the sooner you invest, the more compound growth
        you will enjoy considering wealth creation takes time.
      </p>
      <Grid container spacing={3}>
        <Grid item xs={7}>
          <Paper className={classes.paper}>
            <NumberInput
              label="Initial investment amount"
              onChange={setInitialInvestment}
              prefix="$"
              value={initialInvestment}
            />
            <NumberInput
              label="Regular monthly contribution amount"
              onChange={setRegularContribution}
              prefix="$"
              value={regularContribution}
            />
            <NumberInput
              label="Growth rate"
              onChange={setGrowthRate}
              prefix=""
              suffix=" %"
              value={growthRate}
            />
            <NumberInput
              label="Number of years"
              max={MAX_DURATION}
              onChange={setTotalYears}
              prefix=""
              suffix=" Years"
              value={totalYears}
            />
          </Paper>
        </Grid>
        <Grid item xs={5}>
          <Paper className={classnames(classes.paper, classes.result)}>
            <p>Your investment portfolio is estimated to be worth...</p>
            <p className={classes.resultNumber}>${getCompoundedResult()}</p>
            <p>
              After the inputted number of years based on the inputted growth
              rate and monthly compounding.
            </p>
          </Paper>
        </Grid>
      </Grid>
      <p className={classes.instructions}>
        <em>The Smith Manoeuvre</em> will enable you to build up a portfolio of
        investments starting NOW, meaning you will enjoy the magic of compound
        growth for as long a time as possible.
      </p>
      <Paper className={classes.root}></Paper>
    </div>
  );
}
