export default function getCompoundedPortfolio({
  growthRate,
  initialInvestment,
  regularContribution = 0,
  totalYears,
}) {
  let balance = initialInvestment;

  for (let period = 1; period <= totalYears * 12; period++) {
    balance = balance * (1 + growthRate / 12) + regularContribution;
  }

  return balance;
}
