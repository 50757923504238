import React from "react";
import PropTypes from "prop-types";
import NumberFormat from "react-number-format";
import TextField from "@material-ui/core/TextField";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(theme => ({
  formControl: {
    margin: `${theme.spacing(1)}px 0px`,
  },
}));

function NumberFormatCustom({ inputRef, onChange, suffix, prefix, ...other }) {
  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      isNumericString
      onValueChange={({ value }) => {
        onChange({ target: { value } });
      }}
      prefix={prefix}
      suffix={suffix}
      thousandSeparator
    />
  );
}

NumberFormatCustom.propTypes = {
  inputRef: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  prefix: PropTypes.string,
  suffix: PropTypes.string,
};

NumberFormatCustom.defaultProps = {
  prefix: "$ ",
  suffix: "",
};

export default function NumberInput({
  label,
  max,
  min,
  onChange,
  prefix,
  suffix,
  value,
}) {
  const classes = useStyles();

  function handleChange(event) {
    let { value } = event.target;
    if (min && value < min) {
      value = min;
    } else if (max && value > max) {
      value = max;
    }
    onChange(value);
  }

  return (
    <TextField
      className={classes.formControl}
      fullWidth
      InputProps={{
        inputComponent: NumberFormatCustom,
        inputProps: { prefix, suffix },
      }}
      label={label}
      onChange={handleChange}
      value={value}
      variant="outlined"
    />
  );
}

NumberInput.propTypes = {
  label: PropTypes.string.isRequired,
  max: PropTypes.number,
  min: PropTypes.number,
  onChange: PropTypes.func.isRequired,
  prefix: PropTypes.string,
  suffix: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

NumberInput.defaultProps = {
  max: null,
  min: 0,
  prefix: "$ ",
  suffix: "",
};
