import React, { useState } from "react";
import classnames from "classnames";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import { getBeforeTaxIncomeNeeded } from "maths";
import { H2, NumberInput } from "components";
import { makeStyles } from "@material-ui/core/styles";
import { formatResult } from "./utils";

const MAX_BALANCE = 99999999;
const MAX_RATE = 10;
const MAX_REMAINING = 30;
const MAX_MTR = 65;

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2, 2),
  },
  instructions: {
    fontSize: "14px",
  },
  result: {
    fontSize: "20px",
    textAlign: "center",
  },
  resultNumber: {
    fontSize: "50px",
    fontWeight: "bold",
    margin: "37px 10px",
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
  },
}));

export default function TrueCost() {
  const classes = useStyles();

  const [balance, setBalance] = useState("");
  const [mortgageRate, setMortgageRate] = useState("");
  const [remaining, setRemaining] = useState("");
  const [mtr, setMtr] = useState("");

  function getTrueCost() {
    return [balance, mortgageRate, remaining].every(input => input) &&
      +mtr >= 10
      ? formatResult(
          getBeforeTaxIncomeNeeded({
            balance,
            mtr: mtr / 100,
            rate: mortgageRate / 100,
            totalYears: remaining,
          }),
        )
      : 0;
  }

  return (
    <div className={classes.root}>
      <H2>What Does Your Mortgage Truly Cost You?</H2>
      <p className={classes.instructions}>
        Your mortgage payment is comprised of both an interest and a principal
        component - in other words out of each mortgage payment you make, some
        goes to interest and some to reduce principal. Further, before you can
        make that mortgage payment, you have to pay tax. This is what makes a
        mortgage so terribly expensive. To see how much your current mortgage
        will cost you, please fill out the below fields.
        <br />
        <br />
        WARNING: You may not like the answer!
      </p>
      <Grid container spacing={3}>
        <Grid item xs={7}>
          <Paper className={classes.paper}>
            <NumberInput
              label="Mortgage balance"
              max={MAX_BALANCE}
              onChange={setBalance}
              value={balance}
            />
            <NumberInput
              label="Mortgage rate"
              max={MAX_RATE}
              onChange={setMortgageRate}
              prefix=""
              suffix=" %"
              value={mortgageRate}
            />
            <NumberInput
              label="Amortization remaining"
              max={MAX_REMAINING}
              onChange={setRemaining}
              prefix=""
              suffix=" Years"
              value={remaining}
            />
            <NumberInput
              label="Marginal tax rate"
              max={MAX_MTR}
              onChange={setMtr}
              prefix=""
              suffix=" %"
              value={mtr}
            />
          </Paper>
        </Grid>
        <Grid item xs={5}>
          <Paper className={classnames(classes.paper, classes.result)}>
            <p>You Will Need to Earn a Total of…</p>
            <p className={classes.resultNumber}>${getTrueCost()}</p>
            <p>
              In Order to Fully Pay Out Your Mortgage <strong>Without</strong>{" "}
              <em>The Smith Manoeuvre</em>
            </p>
          </Paper>
        </Grid>
      </Grid>
      <p className={classes.instructions}>
        <em>The Smith Manoeuvre</em> will enable your non-deductible mortgage
        debt to be paid out much sooner than otherwise, meaning less interest
        expense and less total tax paid on income.
      </p>
      <Paper className={classes.root}></Paper>
    </div>
  );
}
