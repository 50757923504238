const middleBarItems = [
  {
    id: 1,
    text: "True Cost of Mortgage",
    to: "/true-cost-calculator",
  },
  {
    id: 2,
    text: "Reverse Mortgage Calculator",
    to: "/reverse-mortgage-calculator",
  },
  {
    id: 3,
    text: "Compound Growth Calculator",
    to: "/compound-growth-calculator",
  },
];

export default middleBarItems;
