export const ageMarks = [
  {
    value: 55,
    label: "55",
  },
  {
    value: 60,
    label: "60",
  },
  {
    value: 65,
    label: "65",
  },
  {
    value: 70,
    label: "70",
  },
  {
    value: 75,
    label: "75",
  },
  {
    value: 80,
    label: "80",
  },
];

export const paybackMarks = [
  {
    value: 5,
    label: "5",
  },
  {
    value: 10,
    label: "10",
  },
  {
    value: 15,
    label: "15",
  },
  {
    value: 20,
    label: "20",
  },
  {
    value: 25,
    label: "25",
  },
  {
    value: 30,
    label: "30",
  },
];
