import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2, 2),
  },
  instructions: {
    fontSize: "14px",
  },
  result: {
    fontSize: "20px",
    textAlign: "center",
  },
  resultNumber: {
    fontSize: "50px",
    fontWeight: "bold",
    margin: "101px 10px",
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
  },
  sliderLabel: {
    marginBottom: "40px",
  },
  topSlider: {
    marginBottom: "30px",
  },
  bottomSlider: {
    marginBottom: "30px",
  },
}));

export default useStyles;
