const topBarItems = [
  {
    id: "media",
    text: "Media",
    to: "https://smithman.net/media/",
  },
  {
    id: "free-resources",
    text: "Free Resources",
    to: "https://smithman.net/free-resources/",
  },
  {
    id: "become-a-professional",
    text: "Find or Become a Smith Manoeuvre Certified Professional",
    to:
      "https://smithman.net/find-or-become-a-smith-manoeuvre-certified-professional/",
  },
  {
    id: "smithman-calculator",
    text: "Smithman Calculator",
    to: "https://calculator.smithman.net",
  },
];

export default topBarItems;
